.faq {
  /* text-align: center; */
  width: 90vw;
  margin: auto;
}
.faq-container {
  display: flex;
  width: 80vw;
  margin: 10px auto;
  justify-content: space-between;
  min-height: 80vh;
}
.faq .h1 {
  /* width: 100px; */
  /* margin: auto; */
  margin-top: 30px;
  /* margin-inline: auto; */
  color: #000000;
}
.que-types {
  width: 250px;
  height: 310px;
  background: #f4f3f4;
  display: flex;
  align-items: center;
  justify-content: center;
}
.que-types ul {
  height: 100%;
  flex-direction: column;
  display: flex;
  padding-left: 0px;
  align-items: center;
  justify-content: space-around;
}
.que-types ul li {
  list-style: none;
}
.que-ans {
  width: 70%;
  margin-top: 90px;
}
.myactive {
  background: #003459;
  color: #fff !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 10px;
  border-radius: 20px;
}
.que-types li a {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  text-decoration: none;
  padding: 10px 15px;
  border-radius: 20px;
}
.faq-container .heading {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  /* text-decoration-line: underline; */
  color: #000000;
}
.each-que-ans {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 49px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  border-top: 1px solid rgb(0 0 0 / 15%);
  color: #000000;
  letter-spacing: 1px;
  word-spacing: 1px;
}

.que-ans .ans {
  padding: 15px;
  background: #ffffff;
  border: 1px solid #d2d2d2;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 15px;
  line-height: 26px;
  color: #000000;
  /* height: 50px; */
  transition: height 0.5s;
  font-weight: 400;
  margin: -6px 0 20px 0;
  /* letter-spacing: 1px; */
  /* word-spacing: 1px; */
}
@media screen and (max-width: 850px) {
  .faq-container {
    width: 95vw;
    justify-content: space-around;
  }
  .que-types {
    width: 150px;
    height: 310px;
    margin-right: 10px;
  }
  .que-types li a {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #000000;
    text-decoration: none;
    padding: 10px 5px;
    border-radius: 20px;
  }
}
