@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap"); */

body {
  margin: 0;
  font-family: "Lato", "Roboto", sans-serif;
  overflow-y: visible;
  overflow-y: scroll;
}

.img {
  pointer-events: none;
}

.user_diy_cart_btn{
  margin: 7px;
    border-radius: 5px;
    background-color: #003459;
    width: 100px;
    border: none;
    height: 40px;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: #ffffff
}
.edit_designhover{
  transition: all 0.5s ease-in-out !important;
}
.edit_designhover:hover{
  background: linear-gradient(300deg, rgba(35,50,154,0.740581266686362) 0%, rgba(141,23,177,0.7685924711681548) 99%) !important;
}
.triangle_arrow{
  width: 20px;
  height: 20px;
  clip-path: polygon(100% 49%, 0 0, 0 100%);
}