.review-popup {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.411);
  z-index: 99;
  display: flex;
  justify-content: center;
  animation: fadeInDown 0.2s ease;
}

.review-cart-popupbox {
  width: 580px;
  height: 400px;
  margin-top: 25vh;
  background-color: white;
  position: fixed;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.review-container {
  width: 500px;
  margin: auto;
  padding: 40px 20px;
}
.review-container .rating p,
.review p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}
.review-title {
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 25px;
  text-align: center;
  color: #f2994a;
}
.review-container textarea {
  background: #ffffff;
  margin-top: 0 !important;
  border: 1px solid rgba(210, 210, 210, 0.56);
}
.review-btn {
  background: #f2994a;
  border: 1px solid #f2994a;
  box-shadow: 0px 4px 4px #757575;
  border-radius: 20px;
  width: 155px;
  height: 40px;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}
.rating {
  display: flex;
  margin: 20px 0;
  /* flex-direction: column; */
  align-items: center;
  column-gap: 30px;
}
.rating .star-box {
  width: 176px;
}
.review {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}
.msg-box {
  /* display: flex; */
  text-align: center;
}
.stars svg {
  width: 35px;
  color: #f2994a;
}
