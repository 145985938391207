.cartItem {
  /* width: 95%; */
  /* height: 190px; */
  /* margin: auto; */
  /* position: relative; */
  /* border-radius: 10px; */
  /* display: flex; */
  /* justify-content: space-around; */
  /* align-items: center; */
  box-shadow: 2px 2px 5px 1px #a3a3a3;
  /* margin-bottom: 10px; */
}

.cartItem .cartItemImg {
  width: 110px;
  /* height: 90%; */
}

.cartItemImg img {
  width: 100%;
  height: 100%;
}

.cartItem .cartItemDetail {
  width: 40%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.cartItemDetail .ciName,
.ciDimension,
.ciMaterial,
.ciPrice {
  font-size: 1.1rem;
  font-weight: 500;
}
.cartItemDetail2 .ciName,
.ciDimension,
.ciMaterial,
.ciPrice {
  font-size: 1rem;
  font-weight: 500;
}

.ciDimension span {
  font-size: 0.9rem;
  font-weight: 400;
}

.ciMaterial span {
  font-size: 0.9rem;
  font-weight: 400;
  text-transform: capitalize;
}

.cartItemDetail .ciPrice .discountedPrice {
  font-size: 1.3rem;
  font-weight: 600;
  color: #003459;
}
.cartItemDetail2 .ciPrice .discountedPrice {
  font-size: 1.1rem;
  font-weight: 600;
  color: #003459;
}

.cartItemDetail .ciPrice .realPrice {
  font-size: 0.9rem;
  font-weight: 500;
  color: #636363;
  text-decoration: line-through;
}
.cartItemDetail2 .ciPrice .realPrice {
  font-size: 0.9rem;
  font-weight: 500;
  color: #636363;
  text-decoration: line-through;
}

.cartItemDetail .ciPrice .discountPercent {
  font-size: 0.9rem;
  font-weight: 400;
  color: #f2994a;
}
.cartItemDetail2 .ciPrice .discountPercent {
  font-size: 0.9rem;
  font-weight: 400;
  color: #f2994a;
}

.cartItem .cartItemQty {
  border: 1px solid #aeaeae;
  border-radius: 4px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-weight: 500;
  font-size: 1rem;
  padding: 4px;
}

.cartItem .cartItemQty img {
  cursor: pointer;
}

.cartItem .cartItemQty .ciQty {
  border-left: 1px solid #636363;
  border-right: 1px solid #636363;
  width: 33%;
  text-align: center;
  padding: 5px 10px;
}

.cartItem .cartItemQty .fa-plus {
  cursor: pointer;
  padding: 5px 10px;
}

.cartItem .cartItemTotal {
  font-size: 1.2rem;
  font-weight: 500;
}

.cartItem .removeCartItem {
  position: absolute;
  top: 4px;
  right: 8px;
  font-size: 1.2rem;
  cursor: pointer;
}

#smallCart {
  display: flex;
}
#bigCart {
  display: none;
}

@media (min-width: 640px) {
  #smallCart {
    display: none;
  }
  #bigCart {
    display: flex;
  }
}
