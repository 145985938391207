.popup {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.411);
  z-index: 99;
  display: flex;
  justify-content: center;
  animation: fadeInDown 0.2s ease;
}

.popupBox {
  width: 40%;
  height: 150px;
  margin-top: 10%;
  background-color: white;
  position: fixed;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cart-popupbox {
  width: 410px;
  height: 425px;
  margin-top: 25vh;
  background-color: white;
  position: fixed;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.cart-popupbox .cross {
  position: absolute;
  top: 10px;
  right: 10px;
}
.cart-popupbox img {
  width: 170px;
  height: 180px;
}
.popup-Head {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  margin: 10px 0;
  line-height: 24px;
}
.popup-desc {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  width: 300px;
  color: #676767;
}
.btn-cart {
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-cart #cart-btn,
.btn-cart a button {
  width: 140px;
  height: 45px;
  line-height: 16px;
  text-align: center;
  border-radius: 5px;
  margin: 10px 10px;
  background: #fff;
  border: 2px solid #003459;

  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #003459;
}
.btn-cart a button {
  background-color: #003459;
  color: white;
}
.order-btn #cart-btn {
  background-color: #003459;
  color: white;
}
.add-to-cart-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    /* transform: translateY(-20px); */
  }
  100% {
    opacity: 1;
    /* transform: translateY(0); */
  }
}
@media screen and (max-width: 550px) {
  .cart-popupbox {
    width: 350px;
    height: 375px;
  }
  .cart-popupbox img {
    width: 175px;
    height: 120px;
  }
  .btn-cart #cart-btn,
  .btn-cart a button {
    width: 140px;
    height: 45px;
    margin-top: 40px;
    font-size: 10px;
  }
}
