.footer {
  width: 100%;
  margin-top: 40px;
  background-color: #003459;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer a {
  text-decoration: none;
}

.footer h2 {
  color: white;
  margin-top: 3px;
  margin-bottom: 10px;
}

.details {
  color: rgb(189, 182, 182);
  margin-top: 0;
  margin-bottom: 5px;
  line-height: 24px;
  font-size: 16px;
}

/* style for footer icons */
.icons-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footerIcon {
  width: 1em;
  height: 1em;
  fill: currentColor;
  flex-shrink: 0;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #ffff;
  margin-right: 20px;
  display: inline-block;
  user-select: none;
  font-size: 30px;
}

.footer h5 {
  color: rgb(189, 182, 182);
}

a {
  color: rgb(189, 182, 182);
  line-height: 24px;
  font-size: 16px;
  text-decoration: none;
}

.flexible_container {
  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto auto;
  justify-content: space-between;
  justify-items: center;
  gap: 20px;
}

.copy_right {
  text-align: center;
  color: white;
  margin-bottom: 0;
  margin-top: 25px;
}

.info_card {
  padding: 10px;
}

.info_card2 {
  padding-top: 50px;
}

.maxwidth {
  width: 1440px;
  padding: 40px;
}

/* hover effects */
a:hover {
  color: white;
}

@media screen and (max-width: 1440px) {
  .maxwidth {
    width: 100%;
    padding: 40px 20px;
  }
}

@media screen and (max-width: 620px) {
  .flexible_container {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: center;
    justify-items: start;
  }
}

@media screen and (max-width: 560px) {
  .maxwidth {
    width: 100%;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .flexible_container {
    display: grid;
    grid-template-columns: auto;
    justify-content: center;
    justify-items: center;
  }

  .info_card {
    text-align: center;
  }

  .info_card2 {
    padding-top: 10px;
  }

  .footerIcon {
    padding-left: 6%;
  }
}

input::placeholder {
  color: #898186;
}

:-ms-input-placeholder {
  color: #898186;
}

::-ms-input-placeholder {
  color: #898186;
}

/* input:focus {
  border: 2px solid #555;
} */

.footer__link__container a:hover {
  color: rgb(59 130 246);
  text-decoration: underline;
}
