@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap");
.error-container {
  margin-top: 50px;
  display: flex;
  padding: 30px 0px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.error-container #error-img {
  width: 400px;
}
.error-container .error-heading {
  font-weight: 600;
  font-size: 30px;
  color: #000000;
}
.error-container .error {

  font-size: 27px;
  color: #000000;
}
.error-container button {
  width: 120px;
  background: #003459;
  border: none;
  margin-top: 20px;
  padding: 3px;
  font-weight: 600;
  font-size: 20px;
  color: #fff;
}
@media screen and (max-width: 550px) {
  .error-container #error-img {
    width: 300px;
  }
}
