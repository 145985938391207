.thankYou{
    height: 60vh;
    width: 70vw;
    margin-left: 15vw;
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 5px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.thankYou .thankHeading{
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: 0.4px;
}

.thankYou .btn-container{
    width: 50%;
    display: flex;
    justify-content: space-around;
}

.thankYou .btn-container button{
    width: 45%;
    font-size: 0.8rem;
    text-transform: uppercase;
    letter-spacing: 0.4px;
    padding: 10px 0px 10px 0px;
    border-radius: 5px;
    outline: none;
    border: none;
    cursor: pointer;
}

.btn-container .continueShopping-btn{
    background-color: rgb(229, 229, 229);
    color: black;
}

.btn-container .goToOrders-btn{
    background-color: #003459;
    color: white;
}